import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProductService } from '../../../shared/service/product.service';

import { Product } from '../../../shared/model/product.model';

@Component({
  selector: 'app-product-catalog-crud',
  templateUrl: './product-catalog-crud.component.html',
  styleUrls: ['./product-catalog-crud.component.scss']
})
export class ProductCatalogCrudComponent implements OnInit {

  gatewayProgress:boolean = false;
  action: string = "enter";

  product: Product = {
    id: null,
    name: null,
    price: null,
    category: 'none',
    logo: null,
    status: 'active',
    method: 'cart'
  };

  categories: any[] = [
    {value: 'none', viewValue: 'Please select a category'},
    {value: 'books', viewValue: 'Books'},
    {value: 'electronics', viewValue: 'Electronics'},
    {value: 'foods', viewValue: 'Food and Drinks'},
    {value: 'grocery', viewValue: 'Grocery and Vegetables'},
    {value: 'households', viewValue: 'Households'},
    {value: 'wearings', viewValue: 'Wearings'},
    {value: 'other', viewValue: 'Other'}
  ];

  purchaseMethods: any[] = [
    {value: 'cart', viewValue: 'Cart'},
    {value: 'token', viewValue: 'Token'}
  ];

  statusList: any[] = [
    {value: 'active', viewValue: 'Active'},
    {value: 'disabled', viewValue: 'Disabled'}
  ];

  uploadedImage: File;  
  uploadedFile: File;
  url: any; 
	msg = "";
  imageFormData:FormData = new FormData();

  constructor(public router: Router, private route: ActivatedRoute, private productService: ProductService) {
    const action = this.route.snapshot.paramMap.get('action');
    if(action){
      this.action = action;
    }
    if(this.action != 'new'){
      const id = this.route.snapshot.paramMap.get('id');
      const result = this.productService.getProduct(parseInt(id));
      if(result){
        this.product = result;
        if(this.product.logo){
          this.url = "data:image/jpeg;base64," + this.product.logo;
        }
      }else{
        this.router.navigate(["/my-apps/product-catalog"]);
      }
    }
  }

  ngOnInit() {
  }

  save(){
    this.gatewayProgress = true;
    this.productService.save(this.product).subscribe( saveResponse => {
      this.gatewayProgress = false;
      if(saveResponse){
        this.product = saveResponse;
        if(this.imageFormData.get("image")){
          this.gatewayProgress = true;
          this.productService.uploadimage(saveResponse.id, this.imageFormData).subscribe( response => {
            this.gatewayProgress = false;
            this.msg = "uploaded";
            this.router.navigate(["/my-apps/product-catalog"]);
          });
        }else{
          this.router.navigate(["/my-apps/product-catalog"]);
        }
      }else{
        this.router.navigate(["/my-apps/product-catalog"]);
      }
    });
    console.log(this.product);
  }

  selectFile(event: any) { //Angular 11, for stricter type
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
    this.uploadedImage = event.target.files[0];
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Only images are supported";
			return;
		}
    var reader = new FileReader();
		reader.readAsDataURL(this.uploadedImage);
		this.gatewayProgress = true;
		reader.onload = (_event) => {
      this.imageFormData.append('image', this.uploadedImage, this.uploadedImage.name);
      this.url = reader.result; 
      this.gatewayProgress = false;
		}
	}

}
