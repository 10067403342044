import { Component, OnInit } from '@angular/core';

import { UserService } from '../shared/service/user.service';

import { User } from '../shared/model/user.model';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  isRegistered = false;
  subscription = "basic";

  profile: User = null;

  constructor(private userService: UserService) {
    this.profile = this.userService.getProfile();
    if(this.profile == null){
      this.userService.retrieveProfile().subscribe( results => {
          if(results){
            this.profile = results;
            this.userService.setProfile(results);
            this.isRegistered = false;
          }
        });
    }else{
      this.isRegistered = false;
    }
  }

  ngOnInit() {
  }

}
