import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RestaurantService } from '../shared/service/restaurant.service';

import { Table } from '../shared/model/table.model';

@Component({
  selector: 'app-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss']
})
export class PointOfSaleComponent implements OnInit {

  table: Table = {
    id: 0,
    name: '',
    clientId: 0,
    status: '',
    flow: 'default'
  }

  action: string = "enter";

  pageLoadforecastProgress: boolean = false;

  flows: any[] = [
    {value: 'default', viewValue: 'Default'},
    {value: 'catalog', viewValue: 'Catalog'},
    {value: 'reseller', viewValue: 'Reseller'}
  ];

  url:string = 'https://market.ipayos.com//index.php?';

  gatewayProgress: boolean = false;


  constructor(public router: Router, private restaurantService: RestaurantService, private route: ActivatedRoute) { 
    const action = this.route.snapshot.paramMap.get('action');
    if(action){
      this.action = action;
    }
    console.log(this.action);
    const id = this.route.snapshot.paramMap.get('id');
    const restaurantId = this.route.snapshot.paramMap.get('restaurantId');
    if (restaurantId && id && this.action){
      this.url = this.url + 'merchant=' + restaurantId + '&terminal=' + id;
      console.log(id);
      if(id){
        const result = this.restaurantService.getTable(parseInt(id));
        console.log(result);
        if(result){
          this.table = result;
        }else{
          this.router.navigate(["/my-apps/shopping-cart"]);
        }
        
        
      }
    }
    this.flows = this.restaurantService.getFlows();
    
  }

  ngOnInit() {
  }

  savePOS(){
    this.pageLoadforecastProgress = true;
    this.restaurantService.saveTable(this.table).subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        console.log(results);
        this.router.navigate(["/my-apps/shopping-cart"]);
      }
    });
  }

  openCart(){
    window.open(this.url); 
  }

}
