import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Demand } from '../model/forecast.model';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  constructor(private http: Http) { }

  public getAll(): Observable<Demand> {
    const url = '/api/distribution-management/getDemand';
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }
}
