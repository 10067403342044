import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { OrderList, Order, OrderItem, BulkUpdate } from '../model/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private orderList:OrderList;

  constructor(private http: Http) { }

  public getAll(status: number, id: number): Observable<OrderList> {
    const url = '/api/order-management/getAll/' + status + '/' + id;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public save(order: Order): Observable<Order> {
    const url = `/api/order-management/save`;
    return this.http.post(url, order)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public bulkUpdate(update: BulkUpdate): Observable<BulkUpdate> {
    const url = `/api/order-management/bulkUpdate`;
    return this.http.post(url, update)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public setOrderList(orderList:OrderList){
    this.orderList = orderList;
  }

  public getOrderList(){
    return this.orderList;
  }

}
