import { Component, OnInit } from '@angular/core';

import { TransactionService } from '../shared/service/transaction.service';
import { UserService } from '../shared/service/user.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  dateFrom = new Date();

  dateTo = new Date();

  transactions: any = [];

  includeTest: boolean = false;

  historyLoadingProgress: boolean = false;

  isAdmin: boolean = false;

  isPartner: boolean = false;

  constructor(private transactionService: TransactionService, private userService: UserService) { }

  ngOnInit() {
    const roles = this.userService.getRoles();
    if(roles == null){
      this.userService.retrieveRoles().subscribe( results => {
        if(results){
          this.userService.setRoles(results);
          console.log(results.indexOf("ipayos_admin"));
          if(results.indexOf("ipayos_admin") > -1){
            this.isAdmin = true;
          }
          if(results.indexOf("ipg_partner") > -1){
            this.isPartner = true;
          }
        }
        
      });
    }else{
      if(roles.indexOf("ipayos_admin") > -1){
        this.isAdmin = true;
      }
      if(roles.indexOf("ipg_partner") > -1){
        this.isPartner = true;
      }
    }
  }

  retrieve(){
    let isTest = 0;
    if(this.includeTest){
      isTest = 1;
    }
    this.historyLoadingProgress = true;
    this.transactionService.history(this.dateFrom.getTime(), this.dateTo.getTime(), isTest).subscribe( results => {
      this.historyLoadingProgress = false;
      this.transactions = results["result"];
    });
  }
}
