import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { Gateway } from '../model/gateway.model';

import { Payment} from '../model/payment.model';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  gateway: Gateway;

  constructor(private http: Http) { }

  public setGateway(gateway:Gateway){
    this.gateway = gateway;
  }

  public getStoredGateway(){
    return this.gateway;
  }
  
  public getGateway(): Observable<Gateway> {
    const url = `/api/gateway/getGateway`;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public updateGateway(gateway: Gateway): Observable<Gateway> {
    const url = `/api/gateway/updateGateway`;
    return this.http.post(url, gateway)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public subscribe(): Observable<Gateway> {
    const url = `/api/gateway/subscribe`;
    return this.http.post(url, null)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public upgrade(): Observable<Payment> {
    const url = `/api/gateway/upgrade`;
    return this.http.post(url, "standard")
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }
}
