import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../../shared/service/user.service';
import { IpgService } from '../../../shared/service/ipg.service';

import { IPGList, IPG } from '../../../shared/model/ipg.model';

@Component({
  selector: 'app-payment-gateway-crud',
  templateUrl: './payment-gateway-crud.component.html',
  styleUrls: ['./payment-gateway-crud.component.scss']
})
export class PaymentGatewayCrudComponent implements OnInit {

  action: string = "enter";

  ipg: IPG = {
    id: null,
    name: null,
    type: null,
    endpoint: null,
    clientID: null,
    token: null,
    secret: null
  };
  pageLoadforecastProgress: boolean = false;

  constructor(public router: Router, private route: ActivatedRoute, private ipgService: IpgService, private userService: UserService) { 

    const action = this.route.snapshot.paramMap.get('action');
    if(action){
      this.action = action;
    }
    console.log(this.action);
    if(this.action != 'new'){
      const id = this.route.snapshot.paramMap.get('id');
      const result = ipgService.getIPG(parseInt(id));
      if(result){
        this.ipg = result;
      }else{
        this.router.navigate(["/my-apps/payment-gateway"]);
      }
    }
  }

  ngOnInit() {
  }

  providerSelect(provider: string){
    if(provider == 'paycorp'){
      this.ipg.type = 1;
    }else if(provider == 'mastercard'){
      this.ipg.type = 2;
    }else{
      this.ipg.type = null;
    }
    console.log("provider : " + this.ipg.type);
  }

  goBack(){
    if((this.action == 'view') || (this.action == 'update')){
      this.router.navigate(["/my-apps/payment-gateway"]);
    }else if(this.action == 'new'){
      if(this.ipg){
        if(this.ipg.type){
          this.ipg.type = null;
        }else{
          this.router.navigate(["/my-apps/payment-gateway"]);
        }
      }else{
        this.router.navigate(["/my-apps/payment-gateway"]);
      }
    }else{
      this.router.navigate(["/my-apps/payment-gateway"]);
    }
  }

  save(){
    this.pageLoadforecastProgress = true;
    this.ipgService.save(this.ipg).subscribe( newIPG => {
      this.pageLoadforecastProgress = false;
      this.router.navigate(["/my-apps/payment-gateway"]);
    });
  }
}
