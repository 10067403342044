import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Inventory, InventoryItem } from '../model/stock.model';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  private inventory: Inventory;

  constructor(private http: Http) { }

  public getAll(): Observable<Inventory> {
    const url = `/api/stock-management//getAll`;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public new(inventoryItem: InventoryItem, quantity: number, reference: String): Observable<InventoryItem>{
    const url = '/api/stock-management//new/inventory/' + quantity;
    return this.http.post(url, inventoryItem)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public setInventory(inventory: Inventory){
    this.inventory = inventory;
  }
}
