import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // onShow = true;  // onShow = true;

  constructor(public router: Router, private location: Location){

    }

  ngOnInit() {
   
    // console.log(this.router.url);
    // console.log(this.location.path());
  }

}
