import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatCardModule , MatSelectModule} from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule,MatExpansionModule, MatInputModule, MatProgressBarModule, MatDialogModule } from "@angular/material";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxSpinnerModule } from "ngx-spinner";

import { NgxKjuaModule } from 'ngx-kjua';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';

import { NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HistoryComponent } from './history/history.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './ui/footer/footer.component';
import { HeaderComponent } from './ui/header/header.component';
import { SideNavComponent } from './ui/side-nav/side-nav.component';
import { ShortcutComponent } from './shortcut/shortcut.component';

import { HttpErrorInterceptor} from './interceptor/http-error.interceptor';
import { KeycloakHttp, KEYCLOAK_HTTP_PROVIDER } from './shared/service/keycloak.http';
import { UserService } from './shared/service/user.service';
import { MerchantsService } from './shared/service/merchants.service';
import { TransactionService } from './shared/service/transaction.service';
import { RestaurantService } from './shared/service/restaurant.service';

import { PaymentGatewayNewComponent } from './payment-gateway-new/payment-gateway-new.component';
import { MerchantsComponent } from './merchants/merchants.component';
import { MerchantComponent, MerchantApprovalDialog } from './merchant/merchant.component';
import { OtpComponent } from './otp/otp.component';
import { MyAppsComponent } from './my-apps/my-apps.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { PointOfSaleComponent } from './point-of-sale/point-of-sale.component';
import { ShoppingCartComponent } from './my-apps/shopping-cart/shopping-cart.component';
import { PaymentGatewayComponent } from './my-apps/payment-gateway/payment-gateway.component';
import { MyBusinessComponent } from './my-business/my-business.component';
import { PaymentGatewayCrudComponent } from './my-apps/payment-gateway/payment-gateway-crud/payment-gateway-crud.component';
import { SettlementsComponent, SettlementApprovalDialog } from './settlements/settlements.component';
import { PaymentGatewayRulesComponent } from './my-apps/payment-gateway/payment-gateway-rules/payment-gateway-rules.component';
import { ProductCatalogComponent } from './my-apps/product-catalog/product-catalog.component';
import { ProductCatalogCrudComponent } from './my-apps/product-catalog/product-catalog-crud/product-catalog-crud.component';
import { OrderManagementComponent, OrderManagementDialog } from './my-apps/order-management/order-management.component';
import { StockManagementComponent, StockManagementDialog } from './my-apps/stock-management/stock-management.component';
import { DistributionManagementComponent } from './my-apps/distribution-management/distribution-management.component';

export function kcInitializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init(environment.keycloakOptions);
        console.log('Keycloak is initialized');
        resolve();
      } catch (error) {
        console.log('Error thrown in init ' + error);
        reject(error);
      }
    });
  };
}

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'history', component: HistoryComponent },
      { path: 'settlements', component: SettlementsComponent },
      { path: 'pricing', component: PricingComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'my-business', component: MyBusinessComponent },
      { path: 'merchants', component: MerchantsComponent },
      { path: 'merchant/:mid', component: MerchantComponent },
      { path: 'payment-gateway-new', component: PaymentGatewayNewComponent },
      { path: 'otp', component: OtpComponent },
      { path: 'my-apps', component: MyAppsComponent},
      { path: 'my-apps/distribution-management', component: DistributionManagementComponent},
      { path: 'my-apps/order-management', component: OrderManagementComponent},
      { path: 'my-apps/stock-management', component: StockManagementComponent},
      { path: 'my-apps/product-catalog', component: ProductCatalogComponent},
      { path: 'my-apps/product-catalog/crud/:action/:id', component: ProductCatalogCrudComponent},
      { path: 'my-apps/product-catalog/crud/:action', component: ProductCatalogCrudComponent},
      { path: 'my-apps/shopping-cart', component: ShoppingCartComponent},
      { path: 'my-apps/payment-gateway', component: PaymentGatewayComponent},
      { path: 'my-apps/payment-gateway/crud/:action/:id', component: PaymentGatewayCrudComponent},
      { path: 'my-apps/payment-gateway/crud/:action', component: PaymentGatewayCrudComponent},
      { path: 'my-apps/payment-gateway/rule/:action', component: PaymentGatewayRulesComponent},
      { path : 'pos', component: PointOfSaleComponent},
      { path : 'pos/:action/:restaurantId/:id', component: PointOfSaleComponent}
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LayoutComponent,
    HistoryComponent,
    PricingComponent,
    FaqComponent,
    FooterComponent,
    HeaderComponent,
    SideNavComponent,
    ShortcutComponent,
    PaymentGatewayComponent,
    PaymentGatewayNewComponent,
    MerchantsComponent,
    MerchantComponent,
    MerchantApprovalDialog,
    OtpComponent,
    MyAppsComponent,
    RestaurantComponent,
    PointOfSaleComponent,
    ShoppingCartComponent,
    MyBusinessComponent,
    PaymentGatewayCrudComponent,
    SettlementsComponent,
    SettlementApprovalDialog,
    OrderManagementDialog,
    PaymentGatewayRulesComponent,
    ProductCatalogComponent,
    ProductCatalogCrudComponent,
    OrderManagementComponent,
    StockManagementComponent,
    StockManagementDialog,
    DistributionManagementComponent
  ],
  entryComponents: [SettlementApprovalDialog, OrderManagementDialog, StockManagementDialog, MerchantApprovalDialog],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    HttpModule,
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule,
    NgbModule,
    ChartsModule,
    SelectDropDownModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressBarModule,
    MatSnackBarModule,
    FlexLayoutModule,
    KeycloakAngularModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    NgxKjuaModule
  ],
  providers: [KEYCLOAK_HTTP_PROVIDER,
    { provide: APP_INITIALIZER, useFactory: kcInitializer, multi: true, deps: [KeycloakService] },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    ThemeService,
    UserService,
    TransactionService,
    MerchantsService,
    RestaurantService,
    MatDatepickerModule,
    MatNativeDateModule 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
