import { Component, OnInit } from '@angular/core';
import { DistributionService } from '../../shared/service/distribution.service';

@Component({
  selector: 'app-distribution-management',
  templateUrl: './distribution-management.component.html',
  styleUrls: ['./distribution-management.component.scss']
})
export class DistributionManagementComponent implements OnInit {

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [
    { data: [], label: 'Demand per Locations' }
  ];

  pageLoadforecastProgress: boolean = false;

  constructor(private distributionService: DistributionService) { 
    this.pageLoadforecastProgress = true;
    this.distributionService.getAll().subscribe( results => {
      this.pageLoadforecastProgress = false;
      this.barChartLabels = results.location;
      this.barChartData = [
        { data: results.demand, label: 'Demand per Locations' }
      ];
    });
  }

  ngOnInit() {
  }

}
