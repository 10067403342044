import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { UserService } from '../../shared/service/user.service';
import { ProductService } from '../../shared/service/product.service';

import { ProductList, Product } from '../../shared/model/product.model';

@Component({
  selector: 'app-product-catalog',
  templateUrl: './product-catalog.component.html',
  styleUrls: ['./product-catalog.component.scss']
})
export class ProductCatalogComponent implements OnInit {

  emptyMessage: string = "There is no products added";

  pageLoadforecastProgress:boolean = false;

  private products: Array<Product>;

  constructor(private productService: ProductService, public router: Router, private userService: UserService) {
    if(!this.userService.hasAccess("product-catalog")){
    }
  }

  ngOnInit() {
    this.retreivProducts();
  }


  retreivProducts(){
    this.pageLoadforecastProgress = true;
    this.productService.getAll().subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        this.products = results.products;
        this.productService.setProductList(results);
      }
    });
  }

  delete(product: Product){
    console.log(product);
    this.pageLoadforecastProgress = true;
    this.productService.delete(product).subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        this.retreivProducts();
      }
    });

  }

}
