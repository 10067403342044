import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../shared/service/user.service';

import { User } from '../shared/model/user.model';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  otp: any = {
    type: 'otp',
    value: null,
    reference: null
  };

  profile: User = null;
  pageLoadProgress: boolean = false;

  lastMobile: string = null;
  newMobile: string = null;

  constructor(private userService: UserService, public router: Router) { 
    this.profile = this.userService.getProfile();
    if(this.profile == null){
      this.pageLoadProgress = true;
      this.userService.retrieveProfile().subscribe( results => {
          this.pageLoadProgress = false;
          this.profile = results;
          this.userService.setProfile(results);
          if(!this.profile.mobileVerfied){
            if(this.profile.mobile){
              if(this.profile.mobile != 'none'){
                this.newMobile = this.profile.mobile;
                this.saveMobile()
              }
            }
          }else{
            this.router.navigate(["/dashboard"]);
          }
          
        });
    }else {
      if(!this.profile.mobileVerfied){
        if(this.profile.mobile){
          if(this.profile.mobile != 'none'){
            this.newMobile = this.profile.mobile;
            this.saveMobile()
          }
        }
      }else{
        this.router.navigate(["/dashboard"]);
      }
    }
  }

  ngOnInit() {
  }

  changeMobile(){
    this.lastMobile = this.profile.mobile;
    this.profile.mobile = null;
  }

  saveMobile(){
    this.pageLoadProgress = true;
    this.userService.verify("mobile", this.newMobile, null).subscribe( results => {
      this.pageLoadProgress = false;
      console.log(results);
      if(results){
        if(results.action == 'otp'){
          this.profile.mobile = this.newMobile;
          this.otp.reference = results.reference;
        }
      }
    });
  }

  verifyOTP(){
    this.pageLoadProgress = true;
    this.userService.verify("otp", this.otp.value, this.otp.reference).subscribe( results => {
      console.log(results);
      if(results){
        if(results.verified){
          this.profile.mobileVerfied = true;
          this.userService.updateProfile(this.profile).subscribe( newProfile => {
            this.pageLoadProgress = false;
            console.log(newProfile);
            if(results){
              this.profile = newProfile;
              this.userService.setProfile(newProfile);
              this.router.navigate(["/dashboard"]);
            }
          });
        }
      }
    });
  }

}
