import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MerchantsService } from '../shared/service/merchants.service';
import { UserService } from '../shared/service/user.service';


import { Merchants } from '../shared/model/merchants.model';

@Component({
  selector: 'app-merchants',
  templateUrl: './merchants.component.html',
  styleUrls: ['./merchants.component.scss']
})
export class MerchantsComponent implements OnInit {

  merchants: Merchants = {
    profiles: []
  };

  pageLoadforecastProgress: boolean = false;

  emptyMessage: string = "Loading..";

  includePending: boolean = true;

  constructor(private merchantsService : MerchantsService, public router: Router, private userService: UserService, 
    private snackBar: MatSnackBar) { 
    if(!this.userService.hasAccess("merchants")){
      this.router.navigate(['dashboard']);
    }
    this.pageLoadforecastProgress = true;
    this.includePending = true;
    this.merchantsService.getMerchants().subscribe( results => {
      this.emptyMessage = "There are no merchants.";
      this.pageLoadforecastProgress = false;
      this.merchants.profiles = results.profiles.filter(e => e.isApproved == 0);
      this.merchantsService.setMerchants(results);
    },
    error => {
      this.emptyMessage = "There are no merchants.";
      this.pageLoadforecastProgress = false;
      this.snackBar.open("Error in retrieving merchants. Please try again later", "OK");
    });

  }

  ngOnInit() {
    
  }

  retrieve(){
    if(this.includePending){
      this.merchants.profiles = this.merchantsService.getStoredMerchants().profiles.filter(e => e.isApproved == 0);
    }else{
      this.merchants.profiles = this.merchantsService.getStoredMerchants().profiles.filter(e => e.isApproved > 0);
    }
  }

}
