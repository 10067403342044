import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../shared/service/user.service';
import { IpgService } from '../../shared/service/ipg.service';

import { IPGList, IPG, IPGRules, IPGRule } from '../../shared/model/ipg.model';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {

  closeResult: string;

  ipsList: IPGList = {
    ipgs : []
  };

  rules: IPGRules = {
    rules: []
  }

  gatewayProgress: boolean = false;


  constructor(private ipgService: IpgService, public router: Router, private userService: UserService, private modalService: NgbModal) {
    if(!this.userService.hasAccess("payment-gateway")){
    }

   }

  ngOnInit() {
    this.retreiveIPGs();
    this.retrievRules();
  }

  deleteIPG(ipg: IPG){
    console.log(ipg);
    this.gatewayProgress = true;
    this.ipgService.deleteIPG(ipg).subscribe( results => {
      this.gatewayProgress = false;
      if(results){
        this.retreiveIPGs();
        this.retrievRules();
      }
    });

  }

  deleteRule(rule: IPGRule){
    console.log(rule);
    this.gatewayProgress = true;
    this.ipgService.deleteRule(rule).subscribe( results => {
      this.gatewayProgress = false;
      if(results){
        this.retrievRules();
      }
    });

  }

  retreiveIPGs(){
    this.gatewayProgress = true;
    this.ipgService.getAllIPGs().subscribe( results => {
      this.gatewayProgress = false;
      if(results){
        this.ipsList = results;
        this.ipgService.setIPGList(this.ipsList);
      }
    });
  }

  retrievRules(){
    this.gatewayProgress = true;
    this.ipgService.getAllRules().subscribe( results => {
      this.gatewayProgress = false;
      if(results){
        this.rules = results;
        this.ipgService.setRules(this.rules);
      }
    });
  }

  getRoutingParameter(id: string){
    if(id == "amount"){
      return "Amount";
    }else {
      return "Currency";
    }
  }
  getRoutingLogic(id: string){
    if(id == "equal"){
      return "==";
    }else if(id == "not-equal"){
      return "!=";
    }else if(id == "greater"){
      return ">";
    }else if(id == "lesser"){
      return "<";
    }else if(id == "greater-equals"){
      return ">=";
    }else{
      return "<=";
    }
  }

}
