import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { IPGList, IPG, IPGRules, IPGRule } from '../model/ipg.model';

@Injectable({
  providedIn: 'root'
})
export class IpgService {

  private ipsList: IPGList;
  private rules: IPGRules;

  constructor(private http: Http) { }

  public setIPGList(ipsList:IPGList){
    this.ipsList = ipsList;
  }

  public setRules(rules:IPGRules){
    this.rules = rules;
  }

  public getAllIPGs(): Observable<IPGList> {
    const url = `/api/ipg/getAll`;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public getAllRules(): Observable<IPGRules> {
    const url = `/api/ipg/getAllRules`;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public save(ipg: IPG): Observable<IPG> {
    const url = `/api/ipg/save`;
    return this.http.post(url, ipg)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public saveRule(ipgRule: IPGRule): Observable<IPGRule> {
    const url = `/api/ipg/saveRule`;
    return this.http.post(url, ipgRule)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public deleteIPG(ipg: IPG): Observable<IPG> {
    const url = `/api/ipg/delete`;
    return this.http.post(url, ipg)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public deleteRule(ipgRule: IPGRule): Observable<IPGRule> {
    const url = `/api/ipg/deleteRule`;
    return this.http.post(url, ipgRule)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public getIPGList(){
    return this.ipsList;
  }

  public getRuleList(){
    return this.rules;
  }

  public getIPG(id: number){
    if(this.ipsList){
      if(this.ipsList.ipgs.length > 0){
        return this.ipsList.ipgs.find(e => e.id == id);
      }else{
        return null;
      }
    }else{
      return null;
    }
    
  }
}
