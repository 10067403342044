import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { Merchants } from '../model/merchants.model';
import { Merchant } from '../model/merchant.model';
import { Upload } from '../model/upload.model';

import { UserService } from '../../shared/service/user.service';
import { User } from '../../shared/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class MerchantsService {

  merchants: Merchants;

  constructor(private http: Http) { 
    

  }

  public getMerchants(): Observable<Merchants> {
    const url = "/admin/merchants";
    return this.http.get(url)
        .map(response => response.json());
  } 

  public setMerchants(merchants: Merchants){
    this.merchants = merchants;
  }

  public getStoredMerchants(){
    return this.merchants;
  }

  public getMerchantByID(id: number){
    if(this.merchants){
      return this.merchants.profiles.find(element => element.id == id);
    }else{
      return null;
    }
    
  }

  public getFile(id: number, filename: string){
    const url = '/admin/viewDoc/' + id + '/' + filename;
    return this.http.get(url)
        .map(response => response.arrayBuffer());
    
  }

  approve(profile: any): Observable<Merchant>{
    const url = "/admin/merchant";
    return this.http.post(url, profile).map(response => response.json());
  }

  public uploadimage(id: number, formData: FormData) : Observable<Upload>{
    return this.http.post('/admin//upload/image/' + id, formData).map(response => response.json());
  }

  public uploadLogo(formData: FormData) : Observable<Upload>{
    return this.http.post('/api/user/upload/image', formData).map(response => response.json());
  }

  public uploadApplication(id: number, formData: FormData) : Observable<Upload>{
    return this.http.post('/admin//upload/file/' + id, formData).map(response => response.json());
  }

  public checkApplicationStatus(id: number) : Observable<Upload>{
    return this.http.get('/admin/status/file/' + id,).map(response => response.json());
  }


}
