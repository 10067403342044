import { Component, OnInit } from '@angular/core';

import { RestaurantService } from '../../shared/service/restaurant.service';
import { UserService } from '../../shared/service/user.service';

import { Tables } from '../../shared/model/tables.model';
import { Table } from '../../shared/model/table.model';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  isEmpty: boolean = true;

  emptyMessage: string = "No terminals created yet.";

  tables:Array<any> = [];

  pageLoadforecastProgress:boolean = false;

  //url:string = 'http://localhost:9090/index.php?';
  url:string = 'https://market.ipayos.com/index.php?';

  restaurantId : number = 0;

  gatewayProgress: boolean = false;

  constructor(private restaurantService: RestaurantService, private userService: UserService) { 
    this.pageLoadforecastProgress = true;
    const user = this.userService.getProfile();
    if(!user){
      this.gatewayProgress = true;
      this.userService.retrieveProfile().subscribe( profile => {
        this.gatewayProgress = false;
        this.userService.setProfile(profile);
        this.restaurantId = profile.id;
        this.url = this.url + 'merchant=' + profile.id;
      });
    }else{
      this.restaurantId = user.id;
      this.url = this.url + 'merchant=' + user.id;
    }
    this.gatewayProgress = true;
    this.restaurantService.getAllTables().subscribe( results => {
      this.gatewayProgress = false;
      this.pageLoadforecastProgress = false;
      if(results){
        console.log(results);
        this.tables = results.tables;
        this.restaurantService.setTables(results);
      }
    });
  }

  ngOnInit() {
  }

  getFlow(id:string){
    return this.restaurantService.getFlow(id).viewValue;
  }

  delete(id: number){
    const table: Table = this.restaurantService.getTable(id);
    this.pageLoadforecastProgress = true;
    this.restaurantService.deleteTable(table).subscribe( deleted => {
      this.restaurantService.getAllTables().subscribe( results => {
        this.pageLoadforecastProgress = false;
        if(results){
          console.log(results);
          this.tables = results.tables;
          this.restaurantService.setTables(results);
        }
      });
    });
  }

  openCart(tableId: number){
    window.open(this.url + '&terminal=' + tableId); 
  }
}
