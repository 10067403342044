import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { MerchantsService } from '../shared/service/merchants.service';

import { UserService } from '../shared/service/user.service';
import { User } from '../shared/model/user.model';
import { Merchant } from '../shared/model/merchant.model';
import { Gateway } from '../shared/model/gateway.model';
import { IpgService } from '../shared/service/ipg.service';

import { IPGList, IPG } from '../shared/model/ipg.model';

export interface DialogData {
  ipg: number;
  status: boolean;
  ipgs: Array<IPG>;
} 

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss']
})
export class MerchantComponent implements OnInit {

  profile: Merchant;
  uploadedImage: File;  
  uploadedFile: File;
  applicationStatus: boolean = false;
  dbImage: any; 

  url: any; //Angular 11, for stricter type
	msg = "";
  categories: any[] = [
    {value: 'none', viewValue: 'Please select a category'},
    {value: 'education', viewValue: 'Education'},
    {value: 'government', viewValue: 'Government'},
    {value: 'restaurant', viewValue: 'Resturant'},
    {value: 'ngo', viewValue: 'NGO'},
    {value: 'consultancy', viewValue: 'Business consultancy'},
    {value: 'other', viewValue: 'Other'}
  ];

  isAdmin: boolean = false;

  isPartner: boolean = false;

  pageLoadforecastProgress = false;

  emptyMessage:string = "There is no clients available";

  ipgList: IPGList = {
    ipgs : []
  };

  constructor(private merchantsService : MerchantsService, private route: ActivatedRoute, public router: Router, 
    private userService : UserService, public dialog: MatDialog, private ipgService: IpgService) { 
    if(!this.userService.hasAccess("merchants")){
      this.router.navigate(['dashboard']);
    }
    const mid = this.route.snapshot.paramMap.get('mid');
    console.log(mid);
    this.profile = this.merchantsService.getMerchantByID(parseInt(mid));
    if(this.profile){
      this.url = "data:image/jpeg;base64," + this.profile.businessLogo;
      this.pageLoadforecastProgress = true;
      this.merchantsService.checkApplicationStatus(this.profile.id).subscribe( response => {
        console.log(response);
        if(response.ref == 1){
          this.applicationStatus = true;
        }
        this.pageLoadforecastProgress = false;
      });
    }else{
      this.pageLoadforecastProgress = true;
      this.merchantsService.getMerchants().subscribe( results => {
        this.merchantsService.setMerchants(results);
        this.profile = this.merchantsService.getMerchantByID(parseInt(mid));
        this.url = "data:image/jpeg;base64," + this.profile.businessLogo;
        this.merchantsService.checkApplicationStatus(this.profile.id).subscribe( response => {
          console.log(response);
          if(response.ref == 1){
            this.applicationStatus = true;
          }
          this.pageLoadforecastProgress = false;
        });
      });
    }
      const roles = this.userService.getRoles();
      if(roles == null){
        this.userService.retrieveRoles().subscribe( results => {
          if(results){
            this.userService.setRoles(results);
            console.log(results.indexOf("ipayos_admin"));
            if(results.indexOf("ipayos_admin") > -1){
              this.isAdmin = true;
            }
            if(results.indexOf("ipg_partner") > -1){
              this.isPartner = true;
            }
          }
          
        });
      }else{
        console.log(roles.indexOf("ipayos_admin"));
        if(roles.indexOf("ipayos_admin") > -1){
          this.isAdmin = true;
        }
        if(roles.indexOf("ipg_partner") > -1){
          this.isPartner = true;
        }
      }
    this.retreiveIPGs();
  }

  ngOnInit() {
  }

  retreiveIPGs(){
    this.ipgList = this.ipgService.getIPGList();
    if(!this.ipgList){
      this.pageLoadforecastProgress = true;
      this.ipgService.getAllIPGs().subscribe( results => {
        this.pageLoadforecastProgress = false;
        if(results){
          this.ipgList = results;
          this.ipgService.setIPGList(this.ipgList);
        }
      });
    }
  }

  openPdf(){
    this.merchantsService.getFile(this.profile.id, "application.pdf").subscribe( response => {
      console.log(response);
      var file = new Blob([response], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        console.log(fileURL);

        // Open new windows and show PDF
        window.open(fileURL); 
      
    });
  }

  approve(){
    this.profile.isApproved = 1;
    this.merchantsService.approve(this.profile).subscribe( response => {
      this.router.navigate(["/merchants"]);
    });
  }

  save(){
    console.log(this.profile);
    this.merchantsService.approve(this.profile).subscribe( response => {
      this.router.navigate(["/merchants"]);
    });
  }

  updateClientStatus(cliendId: number, ipg: number, status: boolean){
      console.log("updateClientStatus", cliendId, ipg, status);
      const dialogRef = this.dialog.open(MerchantApprovalDialog, {
        maxWidth: '100%',
        data: {ipg: ipg, status: status, ipgs: this.ipgList.ipgs}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if(result){
          const targetIndex = this.profile.clients.findIndex(f=>f.id===cliendId); 
          this.profile.clients[targetIndex].isLive = result.status;
          this.profile.clients[targetIndex].ipg = result.ipg;
          this.pageLoadforecastProgress = true;
          this.merchantsService.approve(this.profile).subscribe( response => {
            if(response){
              this.profile = response;
            }
            this.pageLoadforecastProgress = false;
          });
        }
        
      });
  }
  selectFile(event: any) { //Angular 11, for stricter type
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
    this.uploadedImage = event.target.files[0];
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Only images are supported";
			return;
		}
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		this.pageLoadforecastProgress = true;
		reader.onload = (_event) => {
			
      const imageFormData = new FormData();
      imageFormData.append('image', this.uploadedImage, this.uploadedImage.name);
      
      this.merchantsService.uploadimage(this.profile.id , imageFormData).subscribe( response => {
        this.msg = "uploaded";
			  this.url = reader.result; 
        this.profile.businessLogo = response.file;
        this.pageLoadforecastProgress = false;
      });
		}
	}

  selectApplicationFile(event: any) { //Angular 11, for stricter type
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
    this.uploadedFile = event.target.files[0];
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/pdf\/*/) == null) {
			this.msg = "Only pdf is supported";
			return;
		}
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		this.pageLoadforecastProgress = true;
		reader.onload = (_event) => {
			
      const fileFormData = new FormData();
      fileFormData.append('file', this.uploadedFile, this.uploadedFile.name);
      
      this.merchantsService.uploadApplication(this.profile.id , fileFormData).subscribe( response => {
        console.log(response);
        this.applicationStatus = true;
        this.pageLoadforecastProgress = false;
      });
		}
	}

}


@Component({
  selector: 'merchant-approval-dialog',
  templateUrl: 'merchant-approval-dialog.html',
})
export class MerchantApprovalDialog {

  statusList: any[] = [
    {value: true, viewValue: 'Live'},
    {value: false, viewValue: 'Testing'}
  ];

  constructor(
    public dialogRef: MatDialogRef<MerchantApprovalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

  approve(): void {
    this.dialogRef.close(this.data);
  }

}
