import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { Forecast } from '../model/forecast.model';
import { Settlements, Settlement } from '../model/settlement.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: Http) { }

  public getForecast(isTest:any): Observable<Forecast> {
    const url = "/api/txn/getForecast/" + isTest;
    return this.http.get(url)
        .map(response => response.json());
  } 

  public history(from: any, to: any, isTest:number): Observable<Forecast> {
    const url = "/api/txn/history/" + from + "/" + to + "/" + isTest;
    return this.http.get(url)
        .map(response => response.json());
  }

  public settlements(): Observable<Settlements> {
    const url = "/admin/txn/settlements";
    return this.http.get(url)
        .map(response => response.json());
  }

  public settle(settlement: Settlement): Observable<Settlements> {
    const url = `/admin/txn/settlement`;
    return this.http.post(url, settlement)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

}
