import { Component, OnInit, Inject} from '@angular/core';
import { Router } from '@angular/router';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { TransactionService } from '../shared/service/transaction.service';
import { UserService } from '../shared/service/user.service';

import { Settlements, Settlement } from '../shared/model/settlement.model';

export interface DialogData {
  status: string;
  reference: string;
}


@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss']
})
export class SettlementsComponent implements OnInit {

  status: string;
  reference: string;

  pendingSettlements: Settlements = {
    settlements : []
  };

  loadingProgress : boolean = false;

  constructor(private transactionService: TransactionService, private userService: UserService, public router: Router, public dialog: MatDialog) { 
    if(!this.userService.hasAccess("settlements")){
      this.router.navigate(['dashboard']);
    }
    this.retrieveSettlements();
  }

  ngOnInit() {
  }

  retrieveSettlements(){
    this.loadingProgress = true;
    this.transactionService.settlements().subscribe( results => {
      this.loadingProgress = false;
      if(results){
        this.pendingSettlements = results;
        console.log(this.pendingSettlements.settlements.length);
      }
    });
  }

  openDialog(settlement: Settlement): void {
    const dialogRef = this.dialog.open(SettlementApprovalDialog, {
      maxWidth: '50%',
      panelClass: "full-with-dialog",
      data: {status: this.status, reference: this.reference}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result, settlement.merchantId);
      this.loadingProgress = true;
      if(result){
        settlement.status = result.status;
        settlement.reference = result.reference;

        this.transactionService.settle(settlement).subscribe( results => {
          this.loadingProgress = false;
          if(results){
            this.pendingSettlements = results;
            console.log(this.pendingSettlements.settlements.length);
          }
        });
      }
      
    });
  }

}

@Component({
  selector: 'settlement-approval-dialog',
  templateUrl: 'settlement-approval-dialog.html',
})
export class SettlementApprovalDialog {

  constructor(
    public dialogRef: MatDialogRef<SettlementApprovalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  approve(): void {
    this.data.status = "approved";
    this.dialogRef.close(this.data);
  }

  reject(): void {
    this.data.status = "rejected";
    this.dialogRef.close(this.data);
  }

}