export class OrderList {
    orders: Array<Order>;
}

export class Order {
    id: number;
    merchantID: number;
    terminalID: number;
    status: number;
    subTotal: number;
    discount: number;
    tax: number;
    total: number;
    mobile: string;
    email: string;
    address: string;
    items: Array<OrderItem>;
}

export class OrderItem {
    id: number;
    price: number;
    productId: number;
    discount: number;
    quantity: number;
}

export class BulkUpdate {
    criteria: string;
    orderIds: Array<number>;
    numberOfOrders: number;
    currentStatus: number;
    status: number;
    remarks: string;
    orders: Array<Order>;
}