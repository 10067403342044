import { Component, OnInit, Inject} from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { StockService } from '../../shared/service/stock.service';

import { Inventory, InventoryItem } from '../../shared/model/stock.model';

export interface DialogData {
  type: string;
  quanity: number;
  reference: string;
}

@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.scss']
})
export class StockManagementComponent implements OnInit {

  inventory: Array<InventoryItem> = [];

  emptyMessage: string = "There is no inventory available";

  pageLoadforecastProgress:boolean = false;

  constructor(private stockService: StockService, public dialog: MatDialog) { }

  ngOnInit() {
    this.retreivInventory();
  }

  retreivInventory(){
    this.pageLoadforecastProgress = true;
    this.stockService.getAll().subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        this.inventory = results.items;
        console.log(this.inventory);
        this.stockService.setInventory(results);
      }
    });
  }

  openDialog(inventoryItem: InventoryItem, type: string): void {
    const dialogRef = this.dialog.open(StockManagementDialog, {
      width: '100%',
      data: {type: type, quantity: null, reference: null}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed, ' + result.quantity + ", " + result.reference);
      if(result){
        this.pageLoadforecastProgress = true;
        if(type == 'new'){
          inventoryItem.available = result.quantity;
          inventoryItem.token = 0;
        }else{
          inventoryItem.available = 0;
          inventoryItem.token = result.quantity;
        }
        this.stockService.new(inventoryItem, result.quantity, result.reference).subscribe( results => {
          this.pageLoadforecastProgress = false;
          if(results){
            this.retreivInventory();
          }
        });
      }
    });
  }

}

@Component({
  selector: 'stock-management-dialog',
  templateUrl: 'stock-management-dialog.html',
})
export class StockManagementDialog {

  constructor(
    public dialogRef: MatDialogRef<StockManagementDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    save(): void {
      this.dialogRef.close(this.data);
    }

}
