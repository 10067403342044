import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/service/user.service';
import { User } from '../shared/model/user.model';

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.scss']
})
export class ShortcutComponent implements OnInit {

  profile: User;

  isAdmin: boolean = false;

  isPartner: boolean = false;

  isIPGPartner: boolean = false;

  
  isResturant: boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit() {
    const roles = this.userService.getRoles();
    if(roles == null){
      this.userService.retrieveRoles().subscribe( results => {
        if(results){
          this.userService.setRoles(results);
          console.log(results.indexOf("ipayos_admin"));
          if(results.indexOf("ipayos_admin") > -1){
            this.isAdmin = true;
          }
          if(results.indexOf("ipg_partner") > -1){
            this.isIPGPartner = true;
          }
          if(results.indexOf("partner") > -1){
            this.isPartner = true;
          }
        }
        
      });
    }else{
      if(roles.indexOf("ipayos_admin") > -1){
        this.isAdmin = true;
      }
      if(roles.indexOf("ipg_partner") > -1){
        this.isIPGPartner = true;
      }
      if(roles.indexOf("partner") > -1){
        this.isPartner = true;
      }
    }
    
  }

}
