import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPGRule } from '../../../shared/model/ipg.model';

import { IpgService } from '../../../shared/service/ipg.service';

import { RestaurantService } from '../../../shared/service/restaurant.service';
import { UserService } from '../../../shared/service/user.service';

import { Table } from '../../../shared/model/table.model';

@Component({
  selector: 'app-payment-gateway-rules',
  templateUrl: './payment-gateway-rules.component.html',
  styleUrls: ['./payment-gateway-rules.component.scss']
})
export class PaymentGatewayRulesComponent implements OnInit {

  action: string = "enter";
  pageLoadforecastProgress: boolean = false;

  ipgRule: IPGRule = {
    id: 0,
    terminalID: 0,
    terminalName: '',
    ipgID: 0,
    ipgName: '',
    routingParameter: '',
    routingLogic: '',
    routingValue: '',
    ipgType: 0
  }

  terminals: Table[] = [];

  ipgs: any[] = [];

  rules: any[] = [];

  currencies:any [] = [
    {id: "USD", value: "US Dollar"},
    {id: "LKR", value: "Sri Lankan Rupee"},
    {id: "EUR", value: "Euro"}
  ];

  parameters: any[] = [
    {id: "amount", value: "Amount"},
    {id: "currency", value: "Currency"}
  ];

  logics: any[] = [
    {id: "equal", value: "Equals to"},
    {id: "not-equal", value: "Not equals to"},
    {id: "greater", value: "Greater than"},
    {id: "lesser", value: "Lesser than"},
    {id: "greater-equals", value: "Greater than or equals"},
    {id: "lesser-equals", value: "Lesser than or equals"}
  ];

  constructor(public router: Router, private route: ActivatedRoute, private ipgService: IpgService, private restaurantService: RestaurantService, ) {
    this.ipgRule.routingParameter = this.parameters[0].id;
    this.ipgRule.routingLogic = this.logics[0].id;
    const ipgList = ipgService.getIPGList();
    if(ipgList){
      this.ipgs = ipgList.ipgs;
      this.ipgRule.ipgID = this.ipgs[0].id;
    }else{
      this.router.navigate(["/my-apps/payment-gateway"]);
    }

    const ruleList = ipgService.getRuleList();
    if(ruleList){
      this.rules = ruleList.rules;
    }

    this.pageLoadforecastProgress = true;
    this.restaurantService.getAllTables().subscribe( results => {
      this.pageLoadforecastProgress = false;
      this.pageLoadforecastProgress = false;
      if(results){
        console.log(results);
        results.tables.forEach(table => {
          this.terminals.push(table);
          this.ipgRule.terminalID = this.terminals[0].id;
        });
        this.restaurantService.setTables(results);
      }
    });
  }

  ngOnInit() {
  }

  goBack(){
    this.router.navigate(["/my-apps/payment-gateway"]);
  }

  onParamterChange(event: any) {
    if(event.value == 'currency'){
      this.logics = [
        {id: "equal", value: "Equals to"},
        {id: "not-equal", value: "Not equals to"}
      ];
      this.ipgRule.routingValue = this.currencies[0].id;
    }else{
      this.logics = [
        {id: "equal", value: "Equals to"},
        {id: "not-equal", value: "Not equals to"},
        {id: "greater", value: "Greater than"},
        {id: "lesser", value: "Lesser than"},
        {id: "greater-equals", value: "Greater than or equals"},
        {id: "lesser-equals", value: "Lesser than or equals"}
      ];
      this.ipgRule.routingValue = '';
    }
  }

  save(){
    this.pageLoadforecastProgress = true;
    const terminal = this.terminals.find(e => e.id == this.ipgRule.terminalID);
    const ipg = this.ipgs.find(e => e.id == this.ipgRule.ipgID);
    this.ipgRule.ipgName = ipg.name;
    this.ipgRule.ipgType = ipg.type;
    this.ipgRule.terminalName = terminal.name;
    this.ipgService.saveRule(this.ipgRule).subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        this.router.navigate(["/my-apps/payment-gateway"]);
      }
    });
  }

}
