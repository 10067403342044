import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { User } from '../model/user.model';



export class Rekai {
  "type":string;
  "value":string;
  "verified": boolean;
  "action": string;
  "reference": string;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  profile: User;

  roles: Array<string>;

  adminAccess: Array<string> = ["home", "history", "merchants", "settlements"];
  ipgPartnerAccess: Array<string> = ["home", "merchants", "history"];
  salesPartnerAccess: Array<string> = ["home", "merchants", "my-business", "my-apps", "history"];
  userAccess: Array<string> = ["home", "my-business", "my-apps", "shopping-cart", "payment-gateway", "history", "product-catalog"];

  constructor(private http: Http) { }

  public retrieveRoles(): Observable<Array<string>> {
    const url = `/api/user/retrieveRoles`;
    return this.http.get(url)
        .map(response => response.json());
  }

  public retrieveProfile(): Observable<User> {
    const url = `/api/user/retrieveProfile`;
    return this.http.get(url)
        .map(response => response.json());
  }

  public hasAccess(scope: string){
    var result: boolean = false;
    if(this.roles){
      this.roles.forEach(role => {
        if(role == "ipayos_admin"){
          if(this.adminAccess.indexOf(scope) > -1){
            result = true;
          }
        }else if(role == "ipg_partner"){
          if(this.ipgPartnerAccess.indexOf(scope) > -1){
            result = true;
          }
        }else if(role == "partner"){
          if(this.salesPartnerAccess.indexOf(scope) > -1){
            result = true;
          }
        }else{
          if(this.userAccess.indexOf(scope) > -1){
            result = true;
          }
        }
      });
    }else{
      this.retrieveRoles().subscribe( response => {
        if(response){
          this.setRoles(response);
        }
      });
    }
    return result;
  }

  public setProfile(profile: User){
    this.profile = profile;
    this.setRoles(profile.roles);
  }

  public getProfile(){
    return this.profile;
  }

  public setRoles(roles: Array<string>){
    this.roles = roles;
  }

  public getRoles(){
    return this.roles;
  }

  public verify(type: string, mobile: string , reference: string) : Observable<Rekai>{
    var request = {
      type: type,
      value: mobile,
      reference: reference
    };
    return this.http.post('/rekai/verify', request).map(response => response.json());
  }

  public updateProfile(profile: User) : Observable<User>{
    return this.http.post('/api/user/updateProfile', profile).map(response => response.json());
  }

  

}
