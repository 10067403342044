import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

import { UserService } from '../shared/service/user.service';
import { TransactionService } from '../shared/service/transaction.service';

import { Settlement } from '../shared/model/settlement.model';
import { User } from '../shared/model/user.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [
    { data: [], label: 'Transactions per Month' }
  ];

  forecastProgress: boolean = false;

  pageLoadforecastProgress: boolean = false;


  profile: User = null;

  topupAmount: any = 0;
  error: boolean = false;
  errorMessage: any = '';

  includeTest: boolean = false;

  constructor(private userService: UserService, public router: Router,
    private transactionService: TransactionService,private spinner: NgxSpinnerService) {
      this.userService.hasAccess("dashboard");
  }

  public ngOnInit(): void {
    this.profile = this.userService.getProfile();
    if(this.profile == null){
      this.pageLoadforecastProgress = true;
      this.userService.retrieveProfile().subscribe( results => {
          this.pageLoadforecastProgress = false;
          this.profile = results;
          this.userService.setProfile(results);
          this.retrieve();
        });
    }else{
      this.retrieve();
    }
    
  }

  goPage(page){
    this.router.navigate([page]);
  }
  

  retrieve(){
    if(this.profile.mobileVerfied){
      let isTest = 0;
      if(this.includeTest){
        isTest = 1;
      }
      this.forecastProgress = true;
      this.transactionService.getForecast(isTest).subscribe( results => {
        this.forecastProgress = false;
        this.barChartLabels = results.months;
        this.barChartData = [
          { data: results.amounts, label: 'Transactions per Month' }
        ];
      });
    }else{
      this.router.navigate(["/otp"]);
    }
  }

  

  setError(errorMessage){
    this.error = true;
    this.errorMessage = errorMessage;
    setTimeout(() => {
      this.error = false;
      this.errorMessage = "";
    }, 3000);
  }

}
