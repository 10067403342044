import { Component, OnInit, Inject} from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { OrderService } from '../../shared/service/order.service';

import { Order, OrderItem, BulkUpdate } from '../../shared/model/order.model';

export interface DialogData {
  action: string;
  criteria: string;
  value: string;
  status: string;
  remarks: string;
}

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss']
})
export class OrderManagementComponent implements OnInit {

  statusList: any[] = [
    {value: 0, viewValue: 'All'},
    {value: 1, viewValue: 'New'},
    {value: 2, viewValue: 'Accepted'},
    {value: 3, viewValue: 'Processing'},
    {value: 4, viewValue: 'Ready'},
    {value: 5, viewValue: 'Delivered'},
    {value: 10, viewValue: 'Rejected'}
  ];

  status: number = 1;
  orderID: number;

  emptyMessage: string = "There is no orders received";

  pageLoadforecastProgress:boolean = false;

  orders: Array<Order> = [];

  update: BulkUpdate = new BulkUpdate();


  constructor(private orderService: OrderService, public dialog: MatDialog) { 


  }

  ngOnInit() {
    //this.retreivOrders();
  }

  retreivOrders(){
    this.pageLoadforecastProgress = true;
    var id = 0;
    if(this.orderID){
      id = this.orderID;
    }
    this.orderService.getAll(this.status, id).subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        this.orders = results.orders;
        console.log(this.orders);
        this.orderService.setOrderList(results);
      }
    });
  }

  delete(order: Order){
    this.pageLoadforecastProgress = true;
    order.status = 100;
    this.orderService.save(order).subscribe( results => {
      this.pageLoadforecastProgress = false;
      if(results){
        this.retreivOrders();
      }
    });
  }

  bulkUpdate(){
    const dialogRef = this.dialog.open(OrderManagementDialog, {
      width: '100%',
      data: {action: 'bulk', criteria: 'order-priority', value: '', status: '4', remarks: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed, ',  result );
      this.pageLoadforecastProgress = true;
      this.preUpdate(result);
      this.orderService.bulkUpdate(this.update).subscribe( results => {
        this.pageLoadforecastProgress = false;
        if(results){
          this.retreivOrders();
        }
      });
    });
  }

  openDialog(order: Order): void {
    const dialogRef = this.dialog.open(OrderManagementDialog, {
      width: '100%',
      data: {action: 'single', criteria: 'order-id', value: order.id.toString(), status: '4', remarks: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed, ' + result.status + ", " + order.status);
      order.status = result.status;
      this.pageLoadforecastProgress = true;
      this.preUpdate(result);
      this.orderService.bulkUpdate(this.update).subscribe( results => {
        this.pageLoadforecastProgress = false;
        if(results){
          this.retreivOrders();
        }
      });
    });
  }

  preUpdate(data:DialogData){
    console.log("preUpdate : criteria - " + data.criteria);
    this.update.criteria = data.criteria;
    if(data.criteria == "order-priority"){
      console.log("preUpdate : numberOfOrders - " + data.value);
      this.update.numberOfOrders = parseInt(data.value);
      this.update.orderIds = [];
      this.update.currentStatus = 0;
    }else if(data.criteria == "order-status"){
      console.log("preUpdate : currentStatus - " + data.value);
      this.update.currentStatus = parseInt(data.value);
      this.update.orderIds = [];
      this.update.numberOfOrders = 0;
    }else{
      console.log("preUpdate : orderIds - " + data.value);
      this.update.orderIds = data.value.split(',').map((item) => parseInt(item));
      this.update.numberOfOrders = 0;
      this.update.currentStatus = 0;
    }
    console.log("preUpdate : status - " + data.status);
    this.update.status = parseInt(data.status);
    console.log("preUpdate : remarks - " + data.remarks);
    this.update.remarks = data.remarks;
  }

}

@Component({
  selector: 'order-management-dialog',
  templateUrl: 'order-management-dialog.html',
})
export class OrderManagementDialog {

  valuePlaceHolder = "Please enter number of orders. e.g. 100";
  valueLabel = "Number";

  logicList: any [] = [
    {value: "order-priority", viewValue: "First received"},
    {value: "order-id", viewValue: "Order Reference"},
    {value: "order-status", viewValue: "Order Status"}
  ];
  statusList: any[] = [
    {value: '0', viewValue: 'Please select'},
    {value: '1', viewValue: 'New'},
    {value: '2', viewValue: 'Accepted'},
    {value: '3', viewValue: 'Processing'},
    {value: '4', viewValue: 'Ready'},
    {value: '5', viewValue: 'Delivered'},
    {value: '10', viewValue: 'Rejected'},
    {value: '100', viewValue: 'Deleted'}
  ];

    constructor(
      public dialogRef: MatDialogRef<OrderManagementDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.selectLogic();
    }

    selectLogic(){
      console.log("selectLogic called ", this.data.criteria);
      if(this.data.criteria == "order-priority"){
        this.valuePlaceHolder ="Please enter number of orders. e.g. 100";
        this.valueLabel = "Number";
        if(this.data.value == '0'){
          this.data.value = '';
        }
      }else if(this.data.criteria == "order-id"){
        this.valuePlaceHolder ="Please enter comma seperated order id's. e.g. 546, 245, 123";
        this.valueLabel = "ID(s)";
        if(this.data.value == '0'){
          this.data.value = '';
        }
      }else if(this.data.criteria == "order-status"){
        this.valuePlaceHolder ="Please enter current status";
        this.valueLabel = "Current Status";
        if(this.data.value == ''){
          this.data.value = '0';
        }
      }else {
        this.valuePlaceHolder ="Please enter logical value";
        if(this.data.value == '0'){
          this.data.value = '';
        }
      }
    }

    save(): void {
      this.dialogRef.close(this.data);
    }

}
