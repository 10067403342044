import { Component, OnInit } from '@angular/core';


import { GatewayService } from '../shared/service/gateway.service';

import { Gateway } from '../shared/model/gateway.model';

@Component({
  selector: 'app-my-apps',
  templateUrl: './my-apps.component.html',
  styleUrls: ['./my-apps.component.scss']
})
export class MyAppsComponent implements OnInit {

  subscribed:boolean = true;

  gateway: Gateway = {
    userId: null,
    brName: null,
    brNumber: null,
    brAdddress: null,
    businessNature: "none",
    bankAccountName: null,
    bankAccountNo: null,
    bank: null,
    branch: null,
    logo: null,
    clients: []
  };

  isResturant: boolean = false;

  gatewayProgress : boolean = false;

  constructor(private gatewayService: GatewayService) { }

  ngOnInit() {
    this.gateway = this.gatewayService.getStoredGateway();
    console.log(this.gateway);
    if(this.gateway == null){
      this.gatewayProgress = true;
      this.gatewayService.getGateway().subscribe( results => {
        this.gatewayProgress = false;
        if(results){
          this.gateway = results;
          console.log(this.gateway);
          this.gatewayService.setGateway(this.gateway);
          if(this.gateway.businessNature == 'restaurant'){
            this.isResturant = true;
          }
        }
      });
    }else{
      if(this.gateway.businessNature == 'restaurant'){
        this.isResturant = true;
      }
    }
  }

}
