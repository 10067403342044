import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ProductList, Product } from '../model/product.model';
import { Upload } from '../model/upload.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productList: ProductList;

  constructor(private http: Http) { }

  public getAll(): Observable<ProductList> {
    const url = `/api/prodcut-catalog/getAll`;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public save(product: Product): Observable<Product> {
    const url = `/api/prodcut-catalog/save`;
    return this.http.post(url, product)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public delete(product: Product): Observable<Product> {
    const url = `/api/prodcut-catalog/delete`;
    return this.http.post(url, product)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  public uploadimage(id: number, formData: FormData) : Observable<Upload>{
    return this.http.post('/api/prodcut-catalog/uploadImage/'  + id, formData).map(response => response.json());
  }

  public setProductList(productList: ProductList){
    this.productList = productList;
  }

  public getProduct(id: number){
    if(this.productList){
      if(this.productList.products.length > 0){
        return this.productList.products.find(e => e.id == id);
      }else{
        return null;
      }
    }else{
      return null;
    }
  }
}
