import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GatewayService } from '../shared/service/gateway.service';
import { UserService } from '../shared/service/user.service';
import { MerchantsService } from '../shared/service/merchants.service';

import { Gateway } from '../shared/model/gateway.model';


@Component({
  selector: 'app-my-business',
  templateUrl: './my-business.component.html',
  styleUrls: ['./my-business.component.scss']
})
export class MyBusinessComponent implements OnInit {

  gateway: Gateway = {
    userId: null,
    brName: null,
    brNumber: null,
    brAdddress: null,
    businessNature: "none",
    bankAccountName: null,
    bankAccountNo: null,
    bank: null,
    branch: null,
    logo: null,
    clients: []
  };

  pageInProgress: boolean = false;

  subscribed: boolean = false;

  subscriptionAction: String = "Activate";

  uploadedImage: File;  
  uploadedFile: File;
  applicationStatus: boolean = false;
  dbImage: any; 
  url: any; 
	msg = "";

  emptyMessage: string = "There is no clients registered";
  categories: any[] = [
    {value: 'none', viewValue: 'Please select a category'},
    {value: 'education', viewValue: 'Education'},
    {value: 'health', viewValue: 'Health'},
    {value: 'finance', viewValue: 'Finance'},
    {value: 'government', viewValue: 'Government'},
    {value: 'food', viewValue: 'Food'},
    {value: 'transport', viewValue: 'Transport'},
    {value: 'agriculture', viewValue: 'Agriculture'},
    {value: 'it', viewValue: 'IT'},
    {value: 'energy', viewValue: 'Energy'},
    {value: 'consultancy', viewValue: 'Consultancy'},
    //{value: 'manufacturer', viewValue: 'Manufacturer'},
    //{value: 'distributor', viewValue: 'Distributor'},
    //{value: 'supplier', viewValue: 'Supplier'},
    //{value: 'retailer', viewValue: 'Retailer'},
    //{value: 'consultant', viewValue: 'Consultant'},
    //{value: 'association', viewValue: 'Association'},
    {value: 'other', viewValue: 'Other'}
  ];


  constructor(private gatewayService: GatewayService, public router: Router, private userService: UserService, private merchantsService: MerchantsService) {
    if(!this.userService.hasAccess("my-business")){
      this.router.navigate(['dashboard']);
    }

   }

  ngOnInit() {
    this.pageInProgress = true;
    this.gatewayService.getGateway().subscribe( results => {
      this.pageInProgress = false;
      if(results){
        this.subscribed = true;
        this.gateway = results;
        this.url = "data:image/jpeg;base64," + this.gateway.logo;
        this.gatewayService.setGateway(this.gateway);
      }
    });
  }

  saveGeneral(){
    this.pageInProgress = true;
    this.gatewayService.updateGateway(this.gateway).subscribe( results => {
      this.pageInProgress = false;
      if(results){
        this.subscribed = true;
        this.gateway = results;
        this.gatewayService.setGateway(this.gateway);
      }
    });
  }

  subscribe(){
    this.pageInProgress = true;
    this.gatewayService.subscribe().subscribe( results => {
      this.pageInProgress = false;
      if(results){
        this.subscribed = true;
        this.gateway = results;
        this.gatewayService.setGateway(this.gateway);
      }
    });
  }

  newClient(){
    this.pageInProgress = true;
    this.gatewayService.subscribe().subscribe( results => {
      this.pageInProgress = false;
      if(results){
        this.subscribed = true;
        this.gateway = results;
        this.gatewayService.setGateway(this.gateway);
      }
    });
  }

  selectFile(event: any) { //Angular 11, for stricter type
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
    this.uploadedImage = event.target.files[0];
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Only images are supported";
			return;
		}
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		this.pageInProgress = true;
		reader.onload = (_event) => {
			
      const imageFormData = new FormData();
      imageFormData.append('image', this.uploadedImage, this.uploadedImage.name);
      
      this.merchantsService.uploadLogo(imageFormData).subscribe( response => {
        this.pageInProgress = false;
        this.msg = "uploaded";
			  this.url = reader.result; 
        this.gateway.logo = response.file;
        
      });
		}
	}

}
