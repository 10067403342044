import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { Table } from '../model/table.model';
import { Tables } from '../model/tables.model';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  tables: Tables;

  flows: any[] = [
    {value: 'default', viewValue: 'Default'},
    {value: 'catalog', viewValue: 'Catalog'},
    {value: 'reseller', viewValue: 'Reseller'}
  ];

  constructor(private http: Http) { }

  public getAllTables(): Observable<Tables> {
    const url = `/api/shop/getAllTerminals`;
    return this.http.get(url)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  saveTable(table:Table): Observable<Table>{
    const url = `/api/shop/terminal`;
    return this.http.post(url, table)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  setTables(tables: Tables){
    this.tables = tables;
  }

  getTables(){
    return this.tables;
  }

  getTable(id: number){
    if(this.tables){
      return this.tables.tables.find( table => table.id === id );
    }else{
      return null;
    }
    
  }

  deleteTable(table:Table): Observable<Table>{
    const url = `/api/shop/deleteTerminal`;
    return this.http.post(url, table)
        .map(response => {
          if(response['_body'] != "") {
            return response.json();
          }else{
            return null;
          }
        });
  }

  getFlow(id:string){
    return this.flows.find(e => e.value == id);
  }

  getFlows(){
    return this.flows;
  }
}
